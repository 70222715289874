<template>
    <section class="section section--slots">
        <img v-if="$isEnableThemeNoel()" class="event-leaf top-mb left bottom width-2 show-mb" src="/assets/img/event/noel/home/leaf-left-2.svg" alt="leaf">
        <b-container>
            <div class="section__title section--slots__title text-center">
                <div class="start-title">
                    <img src="/assets/img/line-star.png" alt="start"/>
                </div>
                <div class="heading h2">
                    <span>{{sectionTitle}}</span>
                </div>
            </div>
            <b-row class="section--slots__content" :class="{'has-topwin': items && items.length !== 0}">
                <b-col md="8" class="section--slots__left" :class="{'col-md-12': items && items.length === 0}">
                    <b-row>
                        <b-col class="section--slots__content-item" cols="6" :md="items && items.length === 0 ? 3 : 4 " sm="6" xs="6" v-for="(item, index) in itemGame" :key="index">
                            <a @click.prevent="moveTo(item)" href="javascript:void(0)">
                                <b-card tag="article">
                                    <p class="name">{{item.bannerRewardAlt}}</p>
                                    <div class="thumb">
                                        <img :src="item.bannerRewardSrc" loading="lazy" :alt="item.bannerRewardAlt"/>
                                    </div>
                                    <b-card-text class="text-center" v-if="item.data && jackpot(item.data.partner_game_id) > 0">
                                        <s-img width="16px" height="16px" :src="item.iconCurencySrc" :alt="item.iconCurencyAlt"/>
                                        <span>
                                            <animated-number
                                                :value="jackpot(item.data.partner_game_id)"
                                                :formatValue="formatToPrice"
                                                :duration="1000"
                                            /></span>
                                    </b-card-text>
                                    <span class="button">CHƠI NGAY</span>
                                </b-card>
                            </a>
                        </b-col>
                    </b-row>
                    <div class="section-readmore flex-box just-center mt-4">
                        <n-link to="/tro-choi?tab=macau&type=no-hu" class="show-mb">
                            XEM THÊM <i class="las la-arrow-right"></i>
                        </n-link>
                    </div>
                </b-col>
                <b-col md="4" class="section--slots__right" v-if="items.length !== 0">
                    <div class="wrapp-table" id="wrapTableTopWinner">
                        <b-table class="section--slots__table-top-winner scrollbar" striped hover :items="items" responsive="sm" sticky-header>
                            <template v-slot:thead-top>
                                <b-tr>
                                    <b-th colspan="3">
                                        <span>Top Winners</span>
                                    </b-th>
                                </b-tr>
                            </template>
                            <template v-slot:cell(username)="data">
                                <img class="avatar" :src="avatarRandom(avatar)" loading="lazy" alt="avatar"/>
                                <span>{{ data.item.username }}</span>
                            </template>
                            <template v-slot:cell(winlost)="data">
                                <img width="16px" height="16px" src="/assets/img/icon-curency.png" loading="lazy" alt="curency"/>
                                <span class="curency">{{ data.item.winlost | formatCurrency}}</span>
                            </template>
                        </b-table>
                    </div>
                </b-col>
            </b-row>
            <div class="section-readmore flex-box just-center">
                <n-link to="/tro-choi?tab=macau&type=no-hu" class="show-pc">
                    XEM THÊM <i class="las la-arrow-right"></i>
                </n-link>
            </div>
        </b-container>
        <img v-if="$isEnableThemeNoel()" class="event-leaf top-mb width-1 show-mb" src="/assets/img/event/noel/home/leaf-right-2.svg" alt="leaf">
    </section>
</template>
<script>
import AnimatedNumber from 'animated-number-vue';
// eslint-disable-next-line import/no-unresolved
import { numberWithCommas } from '~/helper/index.js';

export default {
    props: ['items'],
    components: {
        AnimatedNumber
    },
    data() {
        return {
            sectionTitle: 'NỔ HŨ KIM CƯƠNG',
            itemGame: [
                {
                    iconCurencySrc: '/assets/img/icon-curency.png',
                    iconCurencyAlt: 'curency',
                    bannerRewardSrc: '/assets/img/slots/slot-thanhxabachxa.jpg',
                    loginRequired: false,
                    bannerRewardAlt: 'Thanh Xà Bạch Xà',
                    data: {
                        display_types: [this.$GAME_DISPLAY_TYPE.SLOT],
                        type: this.$GAME_TYPE.ASIA,
                        partner_provider: this.$PARTNER.TECHPLAY,
                        partner_game_id: 'kts9994'
                    }
                },
                {
                    iconCurencySrc: '/assets/img/icon-curency.png',
                    iconCurencyAlt: 'curency',
                    bannerRewardSrc: '/assets/img/slots/tay-du-than-khi.jpg',
                    loginRequired: false,
                    bannerRewardAlt: 'Tây Du Thần Khí',
                    data: {
                        display_types: [this.$GAME_DISPLAY_TYPE.SLOT],
                        type: this.$GAME_TYPE.ASIA,
                        partner_provider: this.$PARTNER.TECHPLAY,
                        partner_game_id: 'kts9995'
                    }
                },
                {
                    iconCurencySrc: '/assets/img/icon-curency.png',
                    iconCurencyAlt: 'curency',
                    bannerRewardSrc: '/assets/img/slots/slot-bietdoimynhan.jpg',
                    loginRequired: false,
                    bannerRewardAlt: 'Biệt Đội Mỹ Nữ',
                    data: {
                        display_types: [this.$GAME_DISPLAY_TYPE.SLOT],
                        type: this.$GAME_TYPE.ASIA,
                        partner_provider: this.$PARTNER.TECHPLAY,
                        partner_game_id: 'kts9996'
                    }
                },
                {
                    iconCurencySrc: '/assets/img/icon-curency.png',
                    iconCurencyAlt: 'curency',
                    bannerRewardSrc: '/assets/img/slots/tu-linh.jpg',
                    loginRequired: false,
                    bannerRewardAlt: 'Tứ Linh',
                    data: {
                        display_types: [this.$GAME_DISPLAY_TYPE.SLOT],
                        type: this.$GAME_TYPE.ASIA,
                        partner_provider: this.$PARTNER.TECHPLAY,
                        partner_game_id: 'kts9991'
                    }
                },
                {
                    iconCurencySrc: '/assets/img/icon-curency.png',
                    iconCurencyAlt: 'curency',
                    bannerRewardSrc: '/assets/img/slots/star-war.jpg',
                    loginRequired: false,
                    bannerRewardAlt: 'Starwar',
                    data: {
                        display_types: [this.$GAME_DISPLAY_TYPE.SLOT],
                        type: this.$GAME_TYPE.ASIA,
                        partner_provider: this.$PARTNER.TECHPLAY,
                        partner_game_id: 'kts9989'
                    }
                },
                {
                    iconCurencySrc: '/assets/img/icon-curency.png',
                    iconCurencyAlt: 'curency',
                    bannerRewardSrc: '/assets/img/slots/son-tinh-thuy-tinh.jpg',
                    loginRequired: false,
                    bannerRewardAlt: 'Sơn Tinh Thủy Tinh',
                    data: {
                        display_types: [this.$GAME_DISPLAY_TYPE.SLOT],
                        type: this.$GAME_TYPE.ASIA,
                        partner_provider: this.$PARTNER.TECHPLAY,
                        partner_game_id: 'kts9999'
                    }
                },
                {
                    iconCurencySrc: '/assets/img/icon-curency.png',
                    iconCurencyAlt: 'curency',
                    bannerRewardSrc: '/assets/img/slots/corona.jpg',
                    loginRequired: false,
                    bannerRewardAlt: 'Corona',
                    data: {
                        display_types: [this.$GAME_DISPLAY_TYPE.SLOT],
                        type: this.$GAME_TYPE.ASIA,
                        partner_provider: this.$PARTNER.TECHPLAY,
                        partner_game_id: 'kts9974'
                    }
                },
                {
                    iconCurencySrc: '/assets/img/icon-curency.png',
                    iconCurencyAlt: 'curency',
                    bannerRewardSrc: '/assets/img/slots/ankhetravang.jpg',
                    loginRequired: false,
                    bannerRewardAlt: 'Ăn khế trả vàng',
                    data: {
                        display_types: [this.$GAME_DISPLAY_TYPE.SLOT],
                        type: this.$GAME_TYPE.ASIA,
                        partner_provider: this.$PARTNER.TECHPLAY,
                        partner_game_id: 'kts9998'
                    }
                }
            ],
            avatar: [
                '/assets/img/slots/avatar-1.png',
                '/assets/img/slots/avatar-2.png',
                '/assets/img/slots/avatar-3.png',
                '/assets/img/slots/avatar-4.png',
                '/assets/img/slots/avatar-5.png',
                '/assets/img/slots/avatar-6.png'
            ]
        };
    },
    methods: {
        jackpot(item) {
            if (this.$store.state.jackpot[item]) {
                return this.$store.state.jackpot[item];
            }
            return 0;
        },
        formatToPrice(value) {
            return numberWithCommas(value.toFixed(0));
        },
        avatarRandom(listAvatar) {
            return listAvatar[Math.floor((Math.random() * listAvatar.length))];
        },
        moveTo(item) {
            if (item.data) {
                this.$playGame(item.data);
            } else if (this.$isMobile()) {
                if (item.link === '/tro-choi-so') {
                    this.$openLink(`${this.$store.state.mapDomain.GAME_URL}?url=/numbergame`, {
                        newTab: item.newTab
                    });
                } else {
                    this.$openLink(`${this.$store.state.mapDomain.GAME_URL}?url=${item.link}`, {
                        newTab: item.newTab
                    });
                }
            } else {
                this.$router.push(item.link);
            }
        }

    }
};
</script>
